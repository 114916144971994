import React, { useEffect, useContext } from "react";
import {graphql, Link} from "gatsby";
import UKRegions from "../../assets/map/uk-regions/index"
import {SVGMap} from "react-svg-map";
import "../../assets/map/singleRegionMap.scss";
import PageWrapper from "../../components/PageWrapper";
import {Col, Container, Row} from "react-bootstrap";
import {Button, MainTitle, Section, Text, Title} from "../../components/Core";
import styled from "styled-components";
import Image from "gatsby-image";
import Seo from "./../../components/Seo";
import LocalStats from "./LocalStats";
import CheckAvailability from "../../sections/index/CheckAvailability";
import GlobalContext from "../../context/GlobalContext";

const renderCheckAvailability = (Status) => {
    if (Status && (Status === 'live' || Status === 'in_progress')) {
        return (
            <Col sm={12} >
                <Container>
                    <Row className="justify-content-center text-center">
                        <CheckAvailability
                            hideLogo={true}
                            customTitle={""}
                            isFocused={false}
                        />
                    </Row>
                </Container>
            </Col>
        )
    }
}

const StrapiTown = (props) => {
    const gContext = useContext(GlobalContext)
    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])
    
    const SectionStyled = styled(Section)`
      /*background-image: linear-gradient(147deg,
      rgba(236, 236, 236, 1) 0%,
      rgba(84, 84, 212, 0) 100%);*/
    `;

    const City = props.data.strapiTowns
    const AllCities = props.data.strapiRegions.towns

    const region = UKRegions.locations.find(region => region.id === City.region.Slug)

    const map = {
        "label": "Map of UK Regions",
        "viewBox": City.region.Viewbox,
        "preserveAspectRatio": "xMidYMid meet",
        "locations": [region]
    }

    const renderPreviousNextCityNav = () => {

        let prevCity
        let nextCity

        AllCities.find((city, index) => {

            // Find matching City from the AllCities
            if (city.Slug === City.Slug) {

                // Found at index 0
                if (index === 0) {
                    prevCity = AllCities.length - 1
                    nextCity = index + 1
                }
                // Found at last index
                else if (index === AllCities.length - 1) {
                    nextCity = 0
                    prevCity = index - 1
                }
                // Found in between
                else {
                    nextCity = index + 1
                    prevCity = index - 1
                }

            }

        })

        /* Are there more than one cities in the Region? */
        if (AllCities.length > 1) {
            return (
                <Row className="p-md-0">
                    <Col className="col-12 col-md-6 text-center text-md-right">
                        <Link to={`/cities/${AllCities[prevCity].Slug}`}>
                            <Button><i className="fa fa-caret-left pr-3" />Previous City</Button>
                        </Link>

                    </Col>
                    <Col className="col-12 col-md-6 text-center text-md-left mt-4 mt-md-0">
                        <Link to={`/cities/${AllCities[nextCity].Slug}`}>
                            <Button>Next City<i className="fa fa-caret-right pl-3" /></Button>
                        </Link>
                    </Col>
                </Row>
            )
        }

        /* Only one city in the region, so we disable the navigation */
        else {
            return (
                <Row>
                    <Col className="col-12 col-md-6 text-center text-md-right">
                        <Button className="disabled disabledButton"><i className="fa fa-caret-left pr-3" />Previous City</Button>
                    </Col>
                    <Col className="col-12 col-md-6 text-center text-md-left mt-4 mt-md-0">
                        <Button className="disabled disabledButton">Next City<i className="fa fa-caret-right pl-3" /></Button>
                    </Col>
                </Row>
            )
        }


    }

    return (
        <>
            <Seo page={City.Slug}/>
            <PageWrapper footerDark>

                <SectionStyled
                    pt={["160px!important", null, "200px!important"]}
                    pb={["20px!important", null, "100px!important"]}
                >
                    <Container>
                        <Row>
                            <Col sm={12} lg={7} className="text-center">
                                <div className="small-svg-map">
                                    <MainTitle style={{fontSize: '48px'}}>Fibre Broadband in {City.Name}</MainTitle>
                                    <Link to={`/areas/${City.region.Slug}`}>
                                        <Text className="pb-5">
                                            <i className="fa fa-map-marker-alt mr-2"/>{City.region.Name},
                                            United Kingdom</Text>
                                    </Link>
                                    {/*<SVGMap map={map}/>*/}
                                    { renderCheckAvailability(props.data.strapiTowns.Status) }
                                </div>
                            </Col>
                            <Col sm={12} lg={5} className="p-3 mt-5 mt-md-0">
                                <Image alt="" fluid={City.Featured_image?.childImageSharp.fluid}
                                       imgStyle={{borderRadius: '7px'}}/>
                            </Col>
                        </Row>
                    </Container>
                </SectionStyled>

                <LocalStats
                    Size={City.Size}
                    Investment={City.Investment}
                    Status={City.Status}
                    CityName={City.Name}
                    All={props.data}
                />

                <div className="mt-0 mb-5 pb-5">
                    {renderPreviousNextCityNav()}
                </div>
            </PageWrapper>
        </>
    );

}

export const data = graphql`
    query getStrapiTownOnSlug($Slug: String) {
      strapiTowns(Slug: {eq: $Slug}) {
        Name
        Region
        Slug
        Size
        Investment
        Status
        Featured_image{
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
            }
        }
        region {
          Slug
          Name
          Viewbox
        } 
      }
      strapiRegions(towns: {elemMatch: {Slug: {eq: $Slug}}}) {
        towns {
            Slug
        }
      }
    }`

export default StrapiTown
